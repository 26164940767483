@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.navbar .level {
  justify-content: space-evenly;
}
/*# sourceMappingURL=web.c2fd9c89.css.map */
